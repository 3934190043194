import React from 'react'
import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    <h2>Our Philosophy</h2>
    <p>Lawrence Hoffman, CPA, P.C. is a family owned accounting firm. Our firm philosophy
        is to treat our clients as if they are part of the family. We offer all the 
        services the large accounting firms offer but with the personal service only a 
        smaller accounting firm can give. We offer that personal touch that other 
        accounting firms do not.</p>  

    <h2>About Us</h2>
    <p>Lawrence Hoffman, CPA, P.C. is a full service public accounting firm, located 
        in Roslyn, New York and servicing the tri-state area. Financial Statement 
        services offered are either Compilation, Review, Certified Audits and 401k Plan 
        Audits. To the small business market we offer write-up services which include 
        bank reconciliations and payroll tax return preparation. We offer a full range 
        of tax services which include personal income tax returns, corporation tax 
        returns, fiduciary tax returns, estate tax returns and retirement plan returns. 
        We offer financial planning, tax planning and estate planning services. We offer 
        computer consulting services whether for QuickBooks or higher end accounting 
        software from Open Systems Accounting Software. Please contact us to see how we 
        can help you with all your accounting needs. We look forward to the opportunity
        to serve you.</p>
  </Layout>
)

export default IndexPage
